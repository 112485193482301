import React, {forwardRef } from "react";

import logo from './assets/img/logo.gif'; // Tell webpack this JS file uses this image

import moment from "moment-with-locales-es6";
import axios from "axios";
import { SelectPicker, DateRangePicker } from 'rsuite';

import {periodes, EquipeTable, Loader, RentaTable, BoardTable } from './Common';
import { Heading, Tab, Checkbox, Dropdown } from "monday-ui-react-core";
import { startOfMonth, endOfMonth } from 'date-fns';
import { Container } from './pva/Container';

import "monday-ui-react-core/dist/main.css"
import "react-datepicker/dist/react-datepicker.css";
import 'rsuite/dist/styles/rsuite-default.css';

import "./App.scss";

var selectedDateMin ;
var selectedDateMax ;
var showHeures ;

// const monday = mondaySdk();

class App extends React.Component {
	
	/**
	 * Constructeur
	 * @param {*} props 
	 */
	constructor(props) {
		super(props);

		this.state = {
			settings: {},
			context: {
			},
			name: "",
			filterAccount: "",
			filterProject: "",
			dateAccountMin: moment().startOf('month').toDate(),
			dateAccountMax: moment().endOf('month').toDate(),
			showAllTimes: false,
			showHeures: false,
			filterPeriode: "",
			caseAvv: 2, //Tous les projets, AVV inclus
			loader: false,
			api: process.env.REACT_APP_API_URL_RECETTE,
			periodeList: periodes
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleProject = this.handleProject.bind(this);
		this.removeProject = this.removeProject.bind(this);
		this.handleDateMin = this.handleDateMin.bind(this);
		this.handleShowAll = this.handleShowAll.bind(this);
		this.handleAvv = this.handleAvv.bind(this);
		this.handleShowHeures = this.handleShowHeures.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.users_times = [];
		this.sum_users_times = [];
		this.users_ids = [];
		this.accounts = [];
		this.boards = [];
		this.clients = [];
		this.projet_list = [];

		this.const = [];
		this.debug = true;
		
		this.const['dateAccountMin'] = this.state.dateAccountMin;
		this.const['dateAccountMax'] = this.state.dateAccountMax;
		this.const['showAllTimes'] = this.state.showAllTimes;
		this.const['showHeures'] = this.state.showHeures;
		this.const['caseAvv'] = this.state.caseAvv;

	}
  
	/**
	 * Fonction ReactJS d'initialisation
	 */
	componentDidMount() {
		fetch('./api/api.json',{
			headers : { 
				'Content-Type': 'application/json',
				'Accept': 'application/json'
				}
			})
			.then(response => response.json())
			.then(json => {
				this.setState({ api: json.api });
				this.execute()
			})
			.catch(e => console.log('API :: Default'),this.execute())
			;
  	}

	/**
	 * Fonction principale
	 */
	execute(){
		if(typeof this.const['filterAccount'] === 'undefined'){
			this.const['filterAccount'] = 6003102;
		}
		this.getUsers();
	}

	/**
	 * Récupère les utilisateurs sur l'API
	 */
	getUsers(){
		axios(this.state.api+'?name=users&cache='+Date.now()).then(response => response.data).then((data) => {
			if(data.users){
				let json = JSON.parse(data.users);
				console.log('Users :: Loaded from cache');
				this.const['users'] = json.users;
				this.getBoards();
			}else{
			};
		});
	}

	/**
	 * Récupère la liste des boards sur l'API
	 */
	getBoards(){
		console.log('getBoards');
		axios(this.state.api+'?name=boards&cache='+Date.now()).then(response => response.data).then((data) => {
			if(data.boards){
				let json = JSON.parse(data.boards);
				console.log('Boards :: Loaded from cache');
				this.const['accounts'] = json.boards;
				this.getData();
			}else{
			};
		})
		.catch((error) => {
			console.log(error.response)
		});
	}

	getData() {
		let filter = this.const['filterAccount'];
		let dateMin;
		let dateMax;
		let showAll;
		let caseAvv;
		let projects=[], selectedProjectsId=[], projectList=[];
		let projectTmp;
		//console.log("filterProject", this.const['filterProject']);
		if(typeof this.const['filterProject'] === 'undefined' || this.const['filterProject'] === null  || this.const['filterProject'] === ""){
			selectedProjectsId = "";
		}else{
			projectTmp = Object.values(this.const['filterProject']);
			projectTmp.map(function(cValue, idx){
				selectedProjectsId.push(cValue.value);
			})
		}
		if(typeof this.const['dateAccountMin'] === 'undefined' || this.const['dateAccountMin'] === false){
			dateMin = false;
			selectedDateMin = moment().startOf('month').format('YYYY-MM-DD');
		}else{
			dateMin = moment(this.const['dateAccountMin']).startOf('day').format('YYYY-MM-DD');
			selectedDateMin = dateMin;
		}
		if(typeof this.const['dateAccountMax'] === 'undefined' || this.const['dateAccountMax'] === false){
			dateMax = false;
			selectedDateMax = moment().endOf('month').format('YYYY-MM-DD');
		}else{
			dateMax = moment(this.const['dateAccountMax']).startOf('day').format('YYYY-MM-DD');
			selectedDateMax =  dateMax;
		}

		if(typeof this.const['showAllTimes'] === 'undefined' || this.const['showAllTimes'] === false){
			showAll = false;
		}else{
			showAll = this.const['showAllTimes'];
		}

		if(typeof this.const['caseAvv'] !== 'undefined'){
			caseAvv = this.const['caseAvv'];
		}
		let params = '?ws=1&name='+filter+(selectedProjectsId !== "" ? '&project='+selectedProjectsId : '')+(dateMin ? '&dateMin='+dateMin : '')+(dateMax ? '&dateMax='+dateMax : '')+(showAll ? '&showAll='+showAll : '')+(this.const['caseAvv'] !== '' ? '&caseAvv='+caseAvv : 'undefined')+"&cache="+Date.now();
		console.log("params", params);
		
		axios(this.state.api+''+params).then(response => response.data).then((data) => {
			let json = data["ws_"+filter];
			this.const['task_names'] = json.projet;
			
			this.const['clients'] = json.client;
			//console.log('clients :: Loaded from cache', json.client);
			projectList = json.projetList;
			this.const['sum_users_times'] = json.team;

			Object.keys(projectList).map(function(projectId){
				// Si Tous les clients, on exclut les AVV  de la liste des projets
				if (filter === 0 ) {
					if (projectList[projectId].board_id !== 6003102) {
						projects.push({
							label: projectList[projectId].board_name,
							value:  projectList[projectId].board_id.toString(),
							task_names: projectList[projectId].task_names,
							board_name: projectList[projectId].board_name,
							client_name: projectList[projectId].client_name,
							board_id: projectList[projectId].board_id.toString()
						});
					}
				} else {
					projects.push({
						label: projectList[projectId].board_name,
						value:  projectList[projectId].board_id.toString(),
						task_names: projectList[projectId].task_names,
						board_name: projectList[projectId].board_name,
						client_name: projectList[projectId].client_name,
						board_id: projectList[projectId].board_id.toString()
					});
				}
			});			

			projects.sort((a ,b) => ((a.label.toLowerCase() > b.label.toLowerCase()) && 1) || -1);
			this.const['boards'] = projects;

			this.setState({loader: false});
			this.parseState();	
		});
	}	

	/**
	 * Fonction ReactJS qui gère l'affichage
	 * @returns 
	 */
	 render() {
		const tasks = this.state.task_names;
		const sums = this.state.sum_users_times; 
		const filterAccount = this.state.filterAccount;
		const filterProject = this.state.filterProject;
		const caseAvv = this.state.caseAvv;
		const clientRenta = this.state.clients;
		const accounts = [];
		let exportpva = "";
		let titleRenta = "Rentabilité client";
		const onSubmit = (event) => {
            event.preventDefault();
        };
		
		const DropdownProject = forwardRef(({ options, onChange, onOptionRemove }, ref) => (
			<>
			  <Dropdown
				defaultValue={filterProject} 
				  onChange={onChange}
				  onOptionRemove={onOptionRemove}
				  className="multiselection"
				  multi
				  options={options}
				  ref={ref}
			  />
			</>
			)
		);
		if(typeof tasks === 'undefined' || typeof sums === 'undefined'){
			return <div className="App">
				<div className="empty">
					<div className="inner">
						<img src={logo} alt="Agence e+p" />
						<div>Chargement</div>
					</div>
 				</div>
			</div>;
		}else{
			this.state.accounts.map(function(account){
				if(typeof accounts[account.workspace_id] === 'undefined' && account.workspace !== null){
					accounts[account.workspace_id] = [];
					accounts[account.workspace_id]['label'] = account.workspace.name;
					accounts[account.workspace_id]['value'] = account.workspace.id;
				}
			});
			accounts.sort((a ,b) => ((a.label.toLowerCase() > b.label.toLowerCase()) && 1 )|| -1);
			// On supprime tous les ID superflus pour avoir un array compact
			const acc_small = [];
			acc_small.push({'label':'Tous les clients','value':0});
			acc_small.push({'label':'e+p - Agence (Dashboard principal)','value': 6003102});
			accounts.map(function(acc){
				if (acc.value != 6003102) {
					acc_small.push(acc);
				}
			});

			if (filterAccount === 0) {
				if (caseAvv === 0) {
					titleRenta = titleRenta + ' (sans AVV)'
				} else if (caseAvv === 1) {
					titleRenta = titleRenta + ' (AVV)'
				}
			}


			exportpva = "";
			if(typeof filterProject === 'undefined' || filterProject === null || filterProject[0] == null
				 || typeof filterProject[0]['task_names'] === 'undefined' || filterProject[0]['task_names'] == null){
				exportpva = "";
			} else {
				if(this.const['boards'] !== null  && this.const['boards'] !== "")
				 {
					//mise à jour de la liste des tâches du projet sélectionné avant pva
					let projectTmp = Object.values(this.const['boards']);
					projectTmp.map(function(cValue2, idx2){
						if (filterProject[0].board_id === cValue2.value) {
							filterProject[0].task_names = cValue2.task_names;
						}
					})
				}

				exportpva = <div className="filter-option"><Container data={filterProject[0]} debut={selectedDateMin} fin={selectedDateMax} onSubmit={onSubmit} /></div>;
			}

			if(typeof this.const['showHeures'] === 'undefined' || this.const['showHeures'] === false){
				showHeures = false;
			}else{
				showHeures = this.const['showHeures'];
			}

			return <div className="App">
				<Loader state={this.state.loader} />
				<div className="content">
					<div className="top-bar">
						<Tab className="vr" onClick={() => this.props.history.push("/")}>Vue utilisateur</Tab>
						<Tab active>Vue client</Tab>
					</div>
					<div className="title-filtres">
						<strong>Filtres</strong>
					</div>
					<div className="nav-bar">
						<div className="filters">
							<div className="filter-option">
								<label>Client :</label>
								<SelectPicker 
									onChange={this.handleChange}
									defaultValue={filterAccount} 
									data={acc_small} 
									style={{ width: 224 }} 
								/>
							</div>
							<div className="filter-option">
								<label>Projet :</label>
								<DropdownProject options={this.const['boards']} onChange={this.handleProject} onOptionRemove={this.removeProject}
								/>
							</div>
							{ (filterAccount !== 0) ?
							<div className="filter-option">
								<label>Période :</label>
								<DateRangePicker defaultValue={[startOfMonth(new Date()), endOfMonth(new Date())]} onChange={(dateRange) => { this.handleDateMin(dateRange[0]); this.handleDateMax(dateRange[1]); this.handleSubmit(); }} appearance="default" placeholder="Dates" style={{ width: 230 }} character=" - " ranges={periodes} format='DD/MM/YYYY' />
 							</div>
							:""}
							 { (filterAccount !== 0) ?
							<div className="filter-option">
								<Checkbox
									label="Toutes les tâches"
									onChange={(bool) => { this.handleShowAll(bool.target.checked); this.handleSubmit();} }
								/>
							</div> 
							:""}
							{ (filterAccount === 0) ?
							<div className="filter-option">
								<label>AVV :</label>
								<SelectPicker data={[
								{
									label: 'Non',
									value: 0
								},
								{
									label: 'Oui',
									value: 1
								},
								{
									label: 'Tous',
									value: 2
								}
								]} 
								defaultValue={caseAvv} onChange={this.handleAvv}
								/>
							</div>
						   :""}
							<div className="filter-option">
								<Checkbox
									label="Heures"
									onChange={(bool) => { this.handleShowHeures(bool.target.checked);} }
								/>
							</div>
							{exportpva}
						</div>
					</div>

					<section>
						<Heading type={Heading.types.h2} value={titleRenta} />
						<RentaTable clientRenta={clientRenta} showHeures={showHeures} />
					</section>


					{ (sums.length === 0 && filterAccount !== 0) && 
					<section>
						<Heading type={Heading.types.h2} value="Team" />
						<p>Sur la période sélectionnée, aucun temps n'a été saisi.</p>
					</section>
					}
					{ (filterAccount !== 0) ?
						<section>
							{Object.keys(sums).map(function(team_id){
								return <>
									<EquipeTable team={sums[team_id]} view="client" showHeures={showHeures} />
								</>
							})}
						</section> 
					:""}
					{ filterAccount !== 0 && tasks ?
					<section>
						<Heading type={Heading.types.h2} value="Par projets/tâches" />
						<BoardTable tasks={tasks} view="client" showHeures={showHeures} />
					</section>
					:""}
				</div>
			</div>;
		}
	}
	
	/**
	 * Fonction qui change le State sur le bouton Appliquer
	 */
	handleSubmit() {
		this.setState({loader: true});
		this.getData();
	}

	/**
	 * Fonction qui change le State sur la sélection d'un filtre
	 * @param {event} event 
	 */
	handleChange(event) {
		this.const['filterAccount'] = event;
		this.const['filterProject'] = "";
		this.const['caseAvv'] = 2; //tous par défaut
		this.setState({
			filterProject: ""
		  });
		this.setState({loader: true});
		this.execute();
	}

	/**
	 * Fonction qui change le State sur la sélection d'un projet
	 * @param {event} event 
	 */
	 handleProject(event) {
		this.const['filterProject'] = event;
		this.setState({loader: true});
		this.execute();
	}

	/**
	 * Fonction qui enlève un projet de la liste de la sélection d'un projet
	 * @param {event} event 
	 */
	 removeProject(event) {
		let indexToRemove = 0;
		let projectTmp = Object.values(this.const['filterProject']);
		projectTmp.map(function(cValue, idx){
			if (cValue.value === event.value) {
				indexToRemove = idx;
			}
		});
		this.const['filterProject'].splice(indexToRemove, 1);
		this.setState({loader: true});
		this.execute();
	}


	/**
	 * Fonction qui change le State sur la sélection de tous les temps (0 inclus)
	 * @param {boolean} bool 
	 */
	 handleShowAll(bool) {
		if(bool){
			this.const['showAllTimes'] = true;
		}else{
			this.const['showAllTimes'] = false;
		}
		this.setState({showAllTimes: this.const['showAllTimes']});
	}

	/**
	 * Fonction qui change le State sur la sélection de tous les temps (0 inclus)
	 * @param {boolean} bool 
	 */
	 handleShowHeures(bool) {
		if(bool){
			this.const['showHeures'] = true;
		}else{
			this.const['showHeures'] = false;
		}
		this.setState({showHeures: this.const['showHeures']});
	}

	/**
	 * Fonction qui change le State sur le choix d'inclusion des AVV
	 * @param {int} caseAvv 
	 */
	handleAvv(caseAvv) {
		this.const['caseAvv'] = caseAvv;
		this.setState({caseAvv: caseAvv});
		this.setState({loader: true});
		this.execute();
	}

	/**
	 * Fonction qui change le State sur la sélection d'une date minimum
	 * @param {date} date 
	 */
	handleDateMin(date) {
		// this.setState({dateAccountMin: date});
		if(date == null){
			this.const['dateAccountMin'] = false;
		}else{
			this.const['dateAccountMin'] = date;
		}
		this.const['filterPeriode'] = "custom";
		this.setState({filterPeriode: this.const['filterPeriode'], dateAccountMin: this.const['dateAccountMin']});
	}

	/**
	 * Fonction qui change le State sur la sélection d'une période
	 * @param {string} periode 
	 */
	handleDateMax(date) {
		// this.setState({dateAccountMax: date});
		if(date == null){
			this.const['dateAccountMax'] = false;
		}else{
			this.const['dateAccountMax'] = date;
		}
		this.const['filterPeriode'] = "custom";
		this.setState({filterPeriode: this.const['filterPeriode'], dateAccountMax: this.const['dateAccountMax']});
	}

	/**
	 * Fonction qui régénère le State
	 * @param {date} date 
	 */
	parseState(){
		this.setState(this.const);
	}

	/**
	 * Function qui calcule le temps en fonction des dates de début et de fin
	 * @param {date} endDate 
	 * @param {date} startDate 
	 * @returns 
	 */
	calcTime(endDate,startDate){
		let time = Math.ceil((endDate-startDate)/60000);
		time = Math.ceil(time/60);
		return time;
	}
}

export default App;