
    import React, { useState } from "react";
    import logo from './assets/img/logo.gif'; // Tell webpack this JS file uses this image
    import { Icon, Tooltip } from "monday-ui-react-core";
    import { DropdownChevronDown, DropdownChevronRight, Alert, MoveArrowRight } from "monday-ui-react-core/dist/allIcons";
    import ColumnResizer from "react-table-column-resizer";
    import { subMonths, subYears, subQuarters, startOfMonth, endOfMonth, startOfYear, endOfYear, startOfQuarter, endOfQuarter } from 'date-fns';

    
    export const periodes = [
      {
        label: "Mois en cours",
        value: [startOfMonth(new Date()), endOfMonth(new Date())]
      },
      {
        label: "Mois précédent",
        value: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))]
      },
      {
        label: "Trimestre en cours",
        value: [startOfQuarter(new Date()), endOfQuarter(new Date())]
      },
      {
        label: "Trimestre précédent",
        value: [startOfQuarter(subQuarters(new Date(), 1)), endOfQuarter(subQuarters(new Date(), 1))]
      },
      {
        label: "Année en cours",
        value: [startOfYear(new Date()), endOfYear(new Date())]
      },
      {
        label: "Année précédente",
        value: [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))]
      }];
    export var periodeDateMin ;
    export var periodeDateMax ;
    var infodecompte = "";

    export var alertIco = "";


    const useSortableData = (items, config = null) => {
        const [sortConfig, setSortConfig] = React.useState(config);
      
        const sortedItems = React.useMemo(() => {
          let sortableItems = [...items];
          if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
            if (typeof a[sortConfig.key] === 'string' && a[sortConfig.key].toLowerCase() != null
                && typeof b[sortConfig.key] === 'string' && b[sortConfig.key].toLowerCase() != null) {
              if (a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
              }
              if (a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
              }
            } else {
              if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
              }
              if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
              }
            }
              return 0;
            });
          }
          return sortableItems;
        }, [items, sortConfig]);
      
        const requestSort = (key) => {
          let direction = 'ascending';
          if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
          ) {
            direction = 'descending';
          }
          setSortConfig({ key, direction });
        };
      
        return { items: sortedItems, requestSort, sortConfig };
      };
      
      export const RentaTable = (props) => {
        let showHeures = false;
        let clientRenta = props.clientRenta;
        if(typeof props.showHeures !== 'undefined' && props.showHeures) {
            showHeures = true;
        }
        let somme_budget_vendu = 0;
        let somme_board_raf = 0;
        let moyen_board_tjm = 0;
        let moyen_board_tjma = 0;
        let somme_nb_heures_saisies = 0;
        let somme_nb_jours_saisis = 0;
        let somme_nb_jours_vendus = 0;
        let somme_budget_consomme = 0;
        const { items, requestSort, sortConfig } = useSortableData(props.clientRenta);
        const getClassNamesFor = (client_name) => {
          if (!sortConfig && client_name === 'client_name') {
            requestSort('client_name');
          } 
          if (!sortConfig) {
            return;
          }
           return sortConfig.key === client_name ? sortConfig.direction : undefined;
        };
        return (
        <table cellSpacing="0" cellPadding="0" border="1">
            <thead>
              <tr>
                <th width="33%" className="subitem-trait">
                  <button type="button"
                    onClick={() => requestSort('client_name')}
                    className={getClassNamesFor('client_name')}
                  >
                    Client
                  </button>
                </th>
                <ColumnResizer className="columnResizer" />
                <th width="11%">
                  <button type="button"
                    onClick={() => requestSort('budget_consomme')}
                    className={getClassNamesFor('budget_consomme')}
                  >
                    Budget consommé
                  </button>
                </th>
                <ColumnResizer className="columnResizer" />
                <th width="9%">
                  <button type="button"
                    onClick={() => requestSort('board_raf')}
                    className={getClassNamesFor('board_raf')}
                  >
                    Reste à faire
                  </button>
                </th>
                <ColumnResizer className="columnResizer" />
                <th width="9%">
                    <button type="button"
                        onClick={() => requestSort('budget_vendu')}
                        className={getClassNamesFor('budget_vendu')}
                    >
                        Budget vendu
                    </button>
                </th>
                <ColumnResizer className="columnResizer" />
                <th width="9%">
                    <button type="button"
                        onClick={() => requestSort('board_tjm')}
                        className={getClassNamesFor('board_tjm')}
                    >TJM</button>
                </th>
                <ColumnResizer className="columnResizer" />
                <th width="9%">
                    <button type="button"
                        onClick={() => requestSort('tjma')}
                        className={getClassNamesFor('tjma')}
                    >TJMA</button>
                </th>
                <ColumnResizer className="columnResizer" />
                {showHeures && <th width="9%">
                    <button type="button"
                        onClick={() => requestSort('spent')}
                        className={getClassNamesFor('spent')}
                    >Nbr heures saisies</button>
                </th>}
                {showHeures && <ColumnResizer className="columnResizer" />}
                <th width="9%">
                    <button type="button"
                        onClick={() => requestSort('nb_jours_saisis')}
                        className={getClassNamesFor('nb_jours_saisis')}
                    >Nbr jours saisis</button>
                </th>
                <ColumnResizer className="columnResizer" />
                <th width="9%">
                    <button type="button"
                        onClick={() => requestSort('nb_jours_vendus')}
                        className={getClassNamesFor('nb_jours_vendus')}
                    >Nbr jours vendus</button>
                </th>
                <ColumnResizer className="columnResizer" />
                <th width="2%"></th>                
              </tr>
            </thead>
            <tbody>
            {Object.keys(items).map(function(projet){
                somme_budget_consomme = somme_budget_consomme + items[projet].budget_consomme;
                somme_board_raf += items[projet].board_raf / 7;
                somme_budget_vendu += items[projet].budget_vendu;
                moyen_board_tjm = moyen_board_tjm + (items[projet].board_tjm * items[projet].nb_jours_vendus);
                moyen_board_tjma = moyen_board_tjma + (items[projet].board_tjm * items[projet].nb_jours_saisis);
                somme_nb_heures_saisies = somme_nb_heures_saisies + items[projet].spent / 60
                somme_nb_jours_saisis = somme_nb_jours_saisis + items[projet].nb_jours_saisis;
                somme_nb_jours_vendus = somme_nb_jours_vendus + items[projet].nb_jours_vendus;

                let class_color = "", alertVendu = "";
                // Alerte si le budget consommé est supérieur au budget vendu
                if (items[projet].budget_consomme > items[projet].budget_vendu || items[projet].budget_vendu === 0){
                    class_color = 'icon text-rouge';
                }
        
                if (items[projet].budget_consomme > items[projet].budget_vendu ){
                    alertIco = <div className="alert-tooltip"><Tooltip position="bottom" content="Le budget consommé est supérieur au budget vendu."><Icon iconType={Icon.type.SVG} icon={Alert} className={class_color} clickable={false} /></Tooltip></div>;
                } else {
                    alertIco = "";
                }
                
                if (items[projet].budget_vendu === 0){
                    alertVendu = <div className="alert-tooltip"><Tooltip position="bottom" content="Budget vendu manquant"><Icon iconType={Icon.type.SVG} icon={Alert} className="icon text-rouge" clickable={false} /></Tooltip></div>;
                }
        
                if (items[projet].client_name != null) {
                return <>
                <tr key={items[projet].client_name}>
                    <td className={`${class_color} subitem-trait`}>{items[projet].client_name}</td>
                    <td className="columnResizer" />
                    <td className={`${class_color} chiffre`}>{(items[projet].budget_consomme).toFixed(2)} € {alertIco}</td>
                    <td className="columnResizer" />
                    <td className={`${class_color} chiffre`}>{(items[projet].board_raf / 7).toFixed(2)}  j</td>
                    <td className="columnResizer" />
                    <td className={`${class_color} chiffre`}>{(items[projet].budget_vendu ).toFixed(2)} € {alertVendu}</td>
                    <td className="columnResizer" />
                    <td className={`${class_color} chiffre`}>{items[projet].board_tjm.toFixed(2)} €</td>
                    <td className="columnResizer" />
                    <td className={`${class_color} chiffre`}>{items[projet].tjma.toFixed(2)} €</td>
                    <td className="columnResizer" />
                    {showHeures && <td className={`${class_color} chiffre`}>{(items[projet].spent / 60).toFixed(2)} h</td>}
                    {showHeures && <td className="columnResizer" />}
                    <td className={`${class_color} chiffre`}>
                    {items[projet].nb_jours_saisis} j
                    </td>
                    <td className="columnResizer" />
                    <td className={`${class_color} chiffre`}>
                    {items[projet].nb_jours_vendus.toFixed(2)} j
                    </td>
                    <td className="columnResizer" />
                    <td />
                </tr>
                </>
                }
                return null;
            })} 
            </tbody>
            { (clientRenta.length > 1) && <tfoot>
                <tr>
                    <td className="subitem-trait" />
                    <td className="columnResizer" />
                    <td>{somme_budget_consomme.toFixed(2)} €<span>Somme</span></td>
                    <td className="columnResizer" />
                    <td>{somme_board_raf.toFixed(2)} j<span>Somme</span></td>
                    <td className="columnResizer" />
                    <td>{somme_budget_vendu.toFixed(2)} €<span>Somme</span></td>
                    <td className="columnResizer" />
                    { (somme_nb_jours_vendus !== 0) && <td>{(moyen_board_tjm / somme_nb_jours_vendus).toFixed(2)} €<span>Moyen</span></td> }
                    { (somme_nb_jours_vendus === 0) && <td>{(somme_nb_jours_vendus).toFixed(2)} €<span>Moyen</span></td> }
                    <td className="columnResizer" />
                    { (somme_nb_jours_saisis !== 0) && <td>{(moyen_board_tjma / somme_nb_jours_saisis).toFixed(2)} €<span>Moyen</span></td> }
                    { (somme_nb_jours_saisis === 0) && <td>{(somme_nb_jours_saisis).toFixed(2)} €<span>Moyen</span></td> }
                    <td className="columnResizer" />
                    { showHeures && <td>{somme_nb_heures_saisies.toFixed(2)} h<span>Somme</span></td>}
                    { showHeures && <td className="columnResizer" />}
                    <td>{somme_nb_jours_saisis.toFixed(2)} j<span>Somme</span></td>
                    <td className="columnResizer" />
                    <td>{somme_nb_jours_vendus.toFixed(2)} j<span>Somme</span></td>
                    <td className="columnResizer" />
                    <td />
                </tr>
            </tfoot> }
          </table>
        );
      };

      export const BoardTable = (props) => {
        //console.log("databoard", data);
        let viewClient = false;
        if(typeof props.view !== 'undefined' && props.view === "client") {
            viewClient = true;
        }

        let showHeures = false;
        if(typeof props.showHeures !== 'undefined' && props.showHeures) {
            showHeures = true;
        }
        let alertBudget = "";

        const { items, requestSort, sortConfig } = useSortableData(props.tasks);
        const getClassNamesFor = (board_name) => {
            if (!sortConfig && board_name === 'board_name') {
                requestSort('board_name');
            } 
            if (!sortConfig) {
                return;
            }
            return sortConfig.key === board_name ? sortConfig.direction : undefined;
        };
        return (
        <table cellSpacing="0" cellPadding="0" border="1">
            <thead>
                <tr>
                    <th width="35%" className="subitem-trait">
                        <button type="button"
                            onClick={() => requestSort('board_name')}
                            className={getClassNamesFor('board_name')}
                        >
                            Projet
                        </button>
                  </th>
                    <ColumnResizer className="columnResizer" />
                    { viewClient && <th width="10%">
                        <button type="button"
                            onClick={() => requestSort('budget_consomme')}
                            className={getClassNamesFor('budget_consomme')}
                        >
                            Budget consommé
                        </button>
                    </th> }
                    { viewClient && <ColumnResizer className="columnResizer" /> }
                    { viewClient && <th width="10%">
                        <button type="button"
                            onClick={() => requestSort('budget_vendu')}
                            className={getClassNamesFor('budget_vendu')}
                        >
                            Budget vendu {alertBudget}
                        </button>
                    </th> }
                    { viewClient && <ColumnResizer className="columnResizer" /> }
                    { viewClient && <th width="10%">
                        <button type="button"
                            onClick={() => requestSort('board_tjm')}
                            className={getClassNamesFor('board_tjm')}
                        >
                            TJM
                        </button>
                    </th> }
                    { viewClient && <ColumnResizer className="columnResizer" /> }
                    { viewClient && <th width="10%">
                        <button type="button"
                            onClick={() => requestSort('tjma')}
                            className={getClassNamesFor('tjma')}
                        >
                            TJMA
                        </button>
                    </th> }
                    { viewClient && <ColumnResizer className="columnResizer" /> }
                    { showHeures && <th width="10%">
                        <button type="button"
                            onClick={() => requestSort('display')}
                            className={getClassNamesFor('display')}
                        >
                            Nbr heures saisies
                        </button></th> }
                    { showHeures && <ColumnResizer className="columnResizer" /> }
                    <th width="10%">
                        <button type="button"
                            onClick={() => requestSort('nb_jours')}
                            className={getClassNamesFor('nb_jours')}
                        >
                            Nbr jours saisis
                        </button>
                    </th>
                    <ColumnResizer className="columnResizer" />
                    { viewClient && <th width="10%">
                        <button type="button"
                            onClick={() => requestSort('nb_jours_vendus')}
                            className={getClassNamesFor('nb_jours_vendus')}
                        >
                            Nbr jours vendus
                        </button>
                    </th> }
                    { viewClient && <ColumnResizer className="columnResizer" /> }
                    <th/>
                </tr>
            </thead>
            <tbody>
            {Object.keys(items).map(function(boards){
                let board = items[boards];
                
                if(((typeof board.temps_h === 'undefined' || board.temps_h === 0)
                && (typeof board.decompte === 'undefined' || board.decompte === 0)) &&
                (typeof board.total !== 'undefined' && board.total > 0)){
                    alertBudget = <div className="alert-tooltip"><Tooltip position="bottom" content="Il manque le budget vendu pour un board."><Icon iconType={Icon.type.SVG} icon={Alert} className="icon text-rouge" clickable={false} /></Tooltip></div>;
                }
                return <>
                    <TaskListTable boards={board} view={props.view} showHeures={showHeures} />
                </>
            })} 
            </tbody>
          </table>
        );
      };

      
      export const TaskTable = (props) => {
        const [open, setOpen] = useState(false);
        //console.log("databoard", data);
        let viewClient = false;
        if(typeof props.view !== 'undefined' && props.view === "client") {
            viewClient = true;
        }

        let showHeures = false;
        if(typeof props.showHeures !== 'undefined' && props.showHeures) {
            showHeures = true;
        }

        const { items, requestSort, sortConfig } = useSortableData(props.task_names);
        const getClassNamesFor = (name) => {
            if (!sortConfig && name === 'name') {
                requestSort('name');
            } 
            if (!sortConfig) {
                return;
            }
            return sortConfig.key === name ? sortConfig.direction : undefined;
        };
        return (
        <table cellSpacing="0" cellPadding="0" border="1">
            <thead>
                <tr>
                    <th className="subitem-trait" width="40%">
                        <button type="button"
                            onClick={() => requestSort('name')}
                            className={getClassNamesFor('name')}
                        >
                            Tâche
                        </button>
                    </th>
                    <ColumnResizer className="columnResizer" />
                    { showHeures && <th width="15%">
                        <button type="button"
                            onClick={() => requestSort('display')}
                            className={getClassNamesFor('display')}
                        >
                            Nbr heures saisies
                        </button>
                    </th> }
                    { showHeures && <ColumnResizer className="columnResizer" /> }
                    <th width="15%">
                        <button type="button"
                            onClick={() => requestSort('total')}
                            className={getClassNamesFor('total')}
                        >
                            Nbr jours saisis
                        </button>
                    </th>
                    <ColumnResizer className="columnResizer" />
                    { viewClient && <th width="15%">
                        <button type="button"
                            onClick={() => requestSort('nb_j_vendus')}
                            className={getClassNamesFor('nb_j_vendus')}
                        >
                            Nbr jours vendus
                        </button>
                    </th> }
                    { viewClient && <ColumnResizer className="columnResizer" /> }
                    <th />
                </tr>
            </thead>
            <tbody>
            {Object.keys(items).map(function(task_names){
                let task = items[task_names];
                
                let [class_color] = "";
                let nb_jour_saisis = 0;
                let nb_jour_saisis_sub = 0;
                let nb_jour_vendus = 0;
                let nb_jour_vendus_sub = 0;

                if(typeof task.total !== 'undefined' && task.total > 0){
                    nb_jour_saisis = ((task.total/60)/7).toFixed(2); 
                }
                if(typeof task.nb_j_vendus !== 'undefined' && task.nb_j_vendus > 0){
                    nb_jour_vendus = (task.nb_j_vendus).toFixed(2); 
                }

                if(viewClient) {
                    if (nb_jour_saisis > nb_jour_vendus){
                        class_color = 'icon text-rouge';
                    }
                }
                //console.log ("task!", task);
                if (task.is_sub === "parent") {
                    return (
                        <>
                            <tr className="sub-element" onClick={() => setOpen(!open)}>
                                <td className={`${class_color} subitem-trait`}>
                                    {task.name}
                                </td>
                                <td className="columnResizer" />
                                { showHeures && <td className={`${class_color} chiffre`}>{task.display} h</td> }
                                { showHeures && <td className="columnResizer" /> }
                                <td className={`${class_color} chiffre`}>
                                    {nb_jour_saisis} j
                                </td>
                                <td className="columnResizer" />
                                { viewClient && <td className={`${class_color} chiffre`}>{nb_jour_vendus} j</td>}
                                { viewClient && <td className="columnResizer" />}
                                <td />
                            </tr>
                            
                            {task.subs && Object.keys(task.subs).map(function(subs){
                                nb_jour_saisis_sub = 0;
                                let sub = task.subs[subs];
                                if(typeof sub.total !== 'undefined' && sub.total > 0){
                                    nb_jour_saisis_sub = ((sub.total/60)/7).toFixed(2); 
                                }
                                nb_jour_vendus_sub = ((sub.decompte/60)/7).toFixed(2); 
                                //console.log("sub!", sub);
                                return <>
                                <tr className="sub-element" onClick={() => setOpen(!open)}>
                                    <td className="subitem-trait">
                                    <Icon iconType={Icon.type.SVG} icon={MoveArrowRight} clickable={false} /> {sub.name}
                                    </td>
                                    <td className="columnResizer" />
                                    { showHeures && <td className="chiffre">{sub.display} h</td> }
                                    { showHeures && <td className="columnResizer" /> }
                                    <td className="chiffre">
                                        {nb_jour_saisis_sub} j
                                    </td>
                                    <td className="columnResizer" />
                                    { viewClient && <td className="chiffre">{nb_jour_vendus_sub} j</td>}
                                    { viewClient && <td className="columnResizer" />}
                                    <td />
                                </tr>
                                </>
                            })}
                        </>
                    )
                }
                return null;
            })} 
            </tbody>
          </table>
        );
      };

    export const EquipeTable = (props) => {
        const [open, setOpen] = useState(true);
        //console.log("dataTeam", data);
        let showHeures = false;
        if(typeof props.showHeures !== 'undefined' && props.showHeures) {
            showHeures = true;
        }

        let somme_tmp_saisi = 0;
        let somme_tjm = 0;
        let somme_budget_consomme = 0;
        let somme_budget_vendu = 0;
        //console.log("props.team.users", props.team.users);
        const { items, requestSort, sortConfig } = useSortableData(props.team.users);
        const getClassNamesFor = (user_name) => {
            if (!sortConfig && user_name === 'user_name') {
                requestSort('user_name');
            } 
            if (!sortConfig) {
                return;
            }
            return sortConfig.key === user_name ? sortConfig.direction : undefined;
        };

        return (
            <>
            <div className="titleTeam" onClick={() => setOpen(!open)}>
                <h4>{ open ? <Icon iconType={Icon.type.SVG} icon={DropdownChevronDown} /> : <Icon iconType={Icon.type.SVG} icon={DropdownChevronRight} /> } {props.team.name}</h4>
            </div>
            <div className={ open ? '' : 'hidden'}>
            <table cellSpacing="0" cellPadding="0" border="1">
                <thead>
                    <tr>
                        <th width="40%" className="subitem-trait">
                            <button type="button"
                                onClick={() => requestSort('user_name')}
                                className={getClassNamesFor('user_name')}
                                >
                                Collaborateur
                            </button>
                        </th>
                        <ColumnResizer className="columnResizer" />
                        { showHeures && <th width="15%">
                            <button type="button"
                                onClick={() => requestSort('display')}
                                className={getClassNamesFor('display')}
                                >
                                Nbr heures saisies
                            </button></th>}
                        { showHeures && <ColumnResizer className="columnResizer" /> }
                        <th width="15%">
                            <button type="button"
                                onClick={() => requestSort('nb_jours')}
                                className={getClassNamesFor('nb_jours')}
                                >
                                Nbr jours saisis
                            </button></th>
                        <ColumnResizer className="columnResizer" />
                        <th />
                    </tr>
                </thead>	
                    <tbody>
                    {Object.keys(items).map(function(user_id){
                        let total = items[user_id];
                        somme_tmp_saisi = somme_tmp_saisi + total.display;
                        somme_tjm = somme_tjm + total.tjm_taches;
                        somme_budget_consomme = somme_budget_consomme + ( total.budget_consomme ? total.budget_consomme : 0 );
                        somme_budget_vendu = somme_budget_vendu + ( total.budget_vendu ? total.budget_vendu : 0 );
                        return <>
                            <CollabTable total={total} view={props.view} showHeures={props.showHeures}/>
                        </>
                    })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td className="subitem-trait" />
                            <td className="columnResizer" />
                            { showHeures && <td>{somme_tmp_saisi.toFixed(2)} h<span>Somme</span></td>}
                            { showHeures && <td className="columnResizer" />}
                            <td>{(somme_tmp_saisi/7).toFixed(2)} j<span>Somme</span></td>
                            <td className="columnResizer" />
                            <td />
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
        )
    };

    export const CollabTable = (props) => {
        const [open, setOpen] = useState(false);
        let class_color = "";
        let nb_jour_saisis = 0;
        //console.log("data", data);
        let viewClient = false;
        let col_span = 5;
        if(typeof props.view !== 'undefined' && props.view === "client") {
            viewClient = true;
            col_span = 9;
        }

        let showHeures = false;
        if(typeof props.showHeures !== 'undefined' && props.showHeures) {
            showHeures = true;
            col_span = col_span + 2;
        }
        
        class_color = 'icon text-'+props.total.color;

        if(typeof props.total.total !== 'undefined' && props.total.total > 0){
            nb_jour_saisis = props.total.nb_jours; 
        }
        
        // Alerte semaine  si saisie / user supérieure à la "limite" hebdo
        if (props.total.color === 'rouge' || props.total.color === 'orange'){
            alertIco = <Icon iconType={Icon.type.SVG} icon={Alert} className={class_color} clickable={false} />;
        } else {
            alertIco = "";
        }
        

        //console.log("dataTeam", data);
        if (viewClient) {
            return (
            <>
                <tr>
                    <td className="subitem-trait">
                        {props.total.user_name}
                    </td>
                    <td className="columnResizer" />
                    {showHeures && <td className="chiffre">{props.total.display} h</td> }
                    {showHeures && <td className="columnResizer" /> }
                    <td className="chiffre">
                        {nb_jour_saisis} j
                    </td>
                    <td className="columnResizer" />
                    <td />
                </tr>
            </>)
        } else {
            return (
            <>
                <tr onClick={() => setOpen(!open)}>
                    <td className={`${class_color} subitem-trait`}>
                        { open ? <Icon iconType={Icon.type.SVG} icon={DropdownChevronDown} /> : <Icon iconType={Icon.type.SVG} icon={DropdownChevronRight} /> }
                        {props.total.user_name}
                    </td>
                    <td className="columnResizer" />
                    {showHeures && <td className={`${class_color} chiffre`}>{props.total.display} h</td> }
                    {showHeures && <td className="columnResizer" /> }
                    <td className={`${class_color} chiffre`}>
                        {nb_jour_saisis} j
                    </td>
                    <td className="columnResizer" />
                    <td />
                </tr>
                <tr className={ open ? '' : 'hidden'}>
                    <td colSpan={col_span}>
                        <MonthTable month={Object.values(props.total.month)} />
                    </td>
                </tr>
            </>)
        }
    };

    export const TaskListTable = (props) => {
        const [open, setOpen] = useState(false);
        let viewClient = false;
        let col_span = 5;
        if(typeof props.view !== 'undefined' && props.view === "client") {
            viewClient = true;
            col_span = 15;
        }

        let showHeures = false;
        if(typeof props.showHeures !== 'undefined' && props.showHeures) {
            showHeures = true;
            col_span = col_span + 2;
        }
        
        let board = props.boards;
        let class_color = "", alertIco="", alertVendu="";
        let nb_jour_saisis = 0, nb_jour_vendus = 0, board_tjm = 0, budget_vendu = 0, budget_consomme = 0, tjma = 0;

        if(typeof board.total !== 'undefined' && board.total > 0){
            nb_jour_saisis = board.nb_jours; 
        } else {
            nb_jour_saisis = 0;
        }
        if(typeof board.nb_jours_vendus !== 'undefined' && board.nb_jours_vendus > 0){
            nb_jour_vendus = board.nb_jours_vendus; 
        } else {
            nb_jour_vendus = 0;
        }

        if(typeof board.board_tjm !== 'undefined' && board.board_tjm > 0){
            board_tjm = board.board_tjm; 
        }
        if(typeof board.budget_consomme !== 'undefined' && board.budget_consomme > 0){
            budget_consomme = board.budget_consomme; 
        }
        if(typeof board.budget_vendu !== 'undefined' && board.budget_vendu > 0){
            budget_vendu = board.budget_vendu; 
        }

        // Alerte si le budget consommé est supérieur au budget vendu
        if(viewClient) {
            if (budget_consomme > budget_vendu || budget_vendu === 0){
                class_color = 'icon text-rouge';
            }

            if (budget_consomme > budget_vendu){
                alertIco = <div className="alert-tooltip"><Tooltip position="bottom" content="Le budget consommé est supérieur au budget vendu."><Icon iconType={Icon.type.SVG} icon={Alert} className={class_color} clickable={false} /></Tooltip></div>;
            } else {
                alertIco = "";
            }
            
            if(((typeof board.temps_h === 'undefined' || board.temps_h === 0)
            && (typeof board.decompte === 'undefined' || board.decompte === 0)) &&
            (typeof board.total !== 'undefined' && board.total >= 0)){
                alertVendu = <div className="alert-tooltip"><Tooltip position="bottom" content="Budget vendu manquant"><Icon iconType={Icon.type.SVG} icon={Alert} className="icon text-rouge" clickable={false} /></Tooltip></div>;
            }
        }

        if(typeof board.tjma !== 'undefined' && board.tjma > 0){
            tjma = board.tjma; 
        }

        return  (
            <>
        <tr key={board.board_name} onClick={() => setOpen(!open)}>
            <td className={`${class_color} subitem-trait`}>
                { open ? <Icon iconType={Icon.type.SVG} icon={DropdownChevronDown} /> : <Icon iconType={Icon.type.SVG} icon={DropdownChevronRight} /> }
                {board.board_name}
            </td>
            <td className="columnResizer" />
            { viewClient && <td className={`${class_color} chiffre`}>{(budget_consomme).toFixed(2)} € {alertIco}</td> }
            { viewClient && <td className="columnResizer" /> }
            { viewClient && <td className={`${class_color} chiffre`}>{budget_vendu.toFixed(2)} € {alertVendu}</td> }
            { viewClient && <td className="columnResizer" /> }
            { viewClient && <td className={`${class_color} chiffre`}>{board_tjm.toFixed(2)} €</td> }
            { viewClient && <td className="columnResizer" /> }
            { viewClient && <td className={`${class_color} chiffre`}>{tjma.toFixed(2)} €</td> }
            { viewClient && <td className="columnResizer" /> }
            { showHeures && <td className={`${class_color} chiffre`}>{board.display} h</td> }
            { showHeures && <td className="columnResizer" /> }
            <td className={`${class_color} chiffre`}>
                {nb_jour_saisis} j
            </td>
            <td className="columnResizer" />
            { viewClient && <td className={`${class_color} chiffre`}>{nb_jour_vendus.toFixed(2)} j</td> }
            { viewClient && <td className="columnResizer" /> }
            <td />
        </tr>
        <tr className={ open ? '' : 'hidden'}>
            <td colSpan={col_span}>
                <TaskTable task_names={board.task_names} view={props.view} showHeures={showHeures} />
            </td>
        </tr>
        </>
        )
    };

    export const MonthTable = (props) => {
        const { items, requestSort, sortConfig } = useSortableData(props.month);
        const getClassNamesFor = (name) => {
            if (!sortConfig && name === 'name') {
                requestSort('name');
            } 
            if (!sortConfig) {
                return;
            }
            return sortConfig.key === name ? sortConfig.direction : undefined;
        };
        return (
            <table cellSpacing="0" cellPadding="0" border="1" className="subitem-trait">
            <thead>
                <tr>
                    <th width="40%">
                        <button type="button"
                            onClick={() => requestSort('name')}
                            className={getClassNamesFor('name')}
                        >
                        Mois
                      </button></th>
                    <ColumnResizer className="columnResizer" />
                    <th width="15%">
                        <button type="button"
                            onClick={() => requestSort('display')}
                            className={getClassNamesFor('display')}
                        >
                        Nbr heures saisies
                      </button>
                    </th>
                    <ColumnResizer className="columnResizer" />
                    <th width="15%">
                        <button type="button"
                            onClick={() => requestSort('total')}
                            className={getClassNamesFor('total')}
                        >
                        Nbr jours saisis
                      </button>
                    </th>
                    <ColumnResizer className="columnResizer" />
                    <th width="15%"></th>
                    <ColumnResizer className="columnResizer" />
                    <th />
                </tr>
            </thead>	
            <tbody>
            {Object.keys(items).map(function(month){
                    let [class_css_decompte, class_color] = "";
                    let nb_jour_saisis = 0;
                    //console.log("data", data);
            
                    if(typeof items[month].display_decompte !== 'undefined'  && items[month].display_decompte !== null && items[month].display_decompte !== "h"){
                        infodecompte =  <p>(Total : {items[month].display_spent} sur {items[month].display_decompte} vendus)</p>;
                    } else {
                        infodecompte = "";
                    }
                    // console.log(items[month].diff);
                    if(items[month].diff <= 0){
                        class_css_decompte = "col-deco neg";
                    } else{
                        class_css_decompte = "col-deco pos";
                    }
                    // console.log(class_css_decompte);
            
                    // Alerte semaine  si saisie / user supérieure à la "limite" hebdo
                    if (items[month].color === 'rouge' || items[month].color === 'orange'){
                        alertIco = <Icon iconType={Icon.type.SVG} icon={Alert} className={class_color} clickable={false} />;
                    } else {
                        alertIco = "";
                    }
                    
                    class_color = 'icon text-'+items[month].color;
            
                    if(typeof items[month].total !== 'undefined' && items[month].total > 0){
                        nb_jour_saisis = ((items[month].total/60)/7).toFixed(2); 
                    }
                    return  (
                        <>
                        <tr key={items[month].name} className="sub-element">
                            <td className={class_color}>
                                {items[month].name}
                            </td>
                            <td className="columnResizer" />
                            <td className={`${class_color} chiffre`}>
                                {items[month].display} h {alertIco}
                            </td>
                            <td className="columnResizer" />
                            <td className={`${class_color} chiffre`}>
                                {nb_jour_saisis} j
                            </td>
                            <td className="columnResizer" />
                            <td className={class_css_decompte}>
                                {infodecompte}
                            </td>
                            <td className="columnResizer" />
                            <td />
                        </tr>
                    </>
                    )
                })} 
                </tbody>
              </table>
            );
    };
    
    /**
     * Fonction JXS pour gérer l'affichage du loader
     * @param {*} data 
     * @returns 
     */
    export function Loader(data){
        if(data.state){
            return (
                <>
                    <div className="overlay">
                        <div className="inner">
                            <img src={logo} alt="Agence e+p" />
                            <div>Chargement</div>
                        </div>
                    </div>
                </>
            )
        }else{
            return (
                <>
                    <div className="overlay hidden">
                        <div className="inner">
                            <img src={logo} alt="Agence e+p" />
                            <div>Chargement</div>
                        </div>
                    </div>
                </>
            )
        }
    }