import React from 'react';
import btn from '../../assets/img/btn-export.png'; // Tell webpack this JS file uses this image

const Trigger = ({ buttonRef, showModal }) => {
  return (
    <button
      className="btn btn-lg btn-danger center modal-button"
      ref={buttonRef}
      onClick={showModal}
    >
      <img src={btn} alt="Export PVA" />
    </button>
  );
};
export default Trigger;
