import React from 'react';

import { PdfDocument } from "../Pva";
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import moment from "moment";

import { TextField, Dropdown } from "monday-ui-react-core";

export const Form = ({ onSubmit, formData, formDebut, formFin }) => {
  const generatePdfDocument = async (data) => {
  const blob = await pdf((
      <PdfDocument
          title='PVA'
          data={formData}
          debut={formDebut}
          fin={formFin}
      />
  )).toBlob();
  saveAs(blob, formData.board_name + "_pva.pdf");
};

	/**
	 * Fonction qui change la sélection d'une date
	 * @param {date} date 
	 */
  function handleDate(date) {
    formData.pdf_datelim_input = date;
		if(date === null || date === ""){
			formData.pdf_datelim  = "";
		}else{
			formData.pdf_datelim = moment(date).format('DD/MM/yyyy');
		}
	}

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label htmlFor="pdf_prestation">Prestation</label>
        <input type="text" className="form-control" id="pdf_prestation" defaultValue={formData.pdf_prestation} onChange={event => formData.pdf_prestation = event.target.value} />
      </div>
      <div className="form-group">
        <label htmlFor="pdf_devis">N° Devis / BDC</label>
        <input className="form-control" id="pdf_devis" defaultValue={formData.pdf_devis} onChange={event => formData.pdf_devis = event.target.value} />
      </div>
      <div className="form-group">
        <label htmlFor="pdf_modalites">Modalités de facturation</label>
        <input className="form-control" id="pdf_modalites" defaultValue={formData.pdf_modalites} onChange={event => formData.pdf_modalites = event.target.value} />
      </div>
      <div className="form-group">
        <label htmlFor="pdf_datelim_input">Date limite PV</label>
        <TextField id="pdf_datelim_input" size={TextField.sizes.MEDIUM} type="date" value={formData.pdf_datelim_input} selected={formData.pdf_datelim} onChange={(date) => handleDate(date)}  />
      </div>
      <div className="form-group">
        <label htmlFor="pdf_decompte_prec">Solde restant de la période précédente (en jour(s))</label>
        <input type='number' step="0.01" className="form-control" id="pdf_decompte_prec" defaultValue={formData.pdf_decompte_prec} onChange={event => formData.pdf_decompte_prec = event.target.value} />
      </div>
      <div className="form-group">
        <label htmlFor="pdf_comment">Commentaires</label>
        <textarea className="form-control" id="pdf_comment" defaultValue={formData.pdf_comment} onChange={event => formData.pdf_comment = event.target.value} />
      </div>
      <div className="form-group">
        <label htmlFor="pdf_signature">Signature</label>
        <Dropdown  id="pdf_signature" defaultValue={[
            {
              label: 'NGVC',
              value: 1
            }]} options={[
            {
              label: 'NGVC',
              value: 1
            },
            {
              label: 'Perfeo territoire',
              value: 2
            },
            {
              label: 'DACC13',
              value: 3
            }
          ]} className="dropdown-stories-styles_with-chips form-control" clearable={false} searchable={false} onChange={event => formData.pdf_signature = event.value} />
      </div>
      <div className="form-group">
      <button onClick={generatePdfDocument}>Télécharger le PDF</button>
      </div>
    </form>
  );
};
export default Form;
