import React from "react";
import mondaySdk from "monday-sdk-js";

import logo from './assets/img/logo.gif'; // Tell webpack this JS file uses this image

import moment from "moment-with-locales-es6";
import axios from "axios";
import { SelectPicker, DateRangePicker } from 'rsuite';

import {periodes, BoardTable, EquipeTable, Loader} from './Common';
import { Heading, Tab } from "monday-ui-react-core";
import { startOfMonth, endOfMonth } from 'date-fns';

import "monday-ui-react-core/dist/main.css"
import "react-datepicker/dist/react-datepicker.css";
import 'rsuite/dist/styles/rsuite-default.css';

import "./App.scss";


const monday = mondaySdk();

class App extends React.Component {
	
	/**
	 * Constructeur
	 * @param {*} props 
	 */
	constructor(props) {
		super(props);

		this.state = {
			settings: {},
			context: {
			},
			name: "",
			filterUser: "",
			dateUserMin: moment().startOf('month').toDate(),
			dateUserMax: moment().endOf('month').toDate(),
			filterPeriode: "",
			loader: false,
			api: process.env.REACT_APP_API_URL_RECETTE,
			me: "",
			teams: [
				392080,		// Media
				392081,		// Tech
				444811,		// AVV
				393531,		// Créa
				444809,		// CDP 360
				444812,		// RH/Finance
				444810,		// Stratégie
				// 505163,		// Anciens collaborateurs
			],
			periodeList: periodes
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleDateMin = this.handleDateMin.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.users_times = [];
		this.sum_users_times = [];
		this.users_ids = [];
		this.accounts = [];
		this.boards = [];

		this.const = [];

		this.const['dateUserMin'] = this.state.dateUserMin;
		this.const['dateUserMax'] = this.state.dateUserMax;

	}
  
	/**
	 * Fonction ReactJS d'initialisation
	 */
	componentDidMount() {
		fetch('./api/api.json',{
			headers : { 
				'Content-Type': 'application/json',
				'Accept': 'application/json'
				}
			})
			.then(response => response.json())
			.then(json => {
				this.setState({ api: json.api });
				this.execute()
			})
			.catch(e => console.log('API :: Default'),this.execute())
			;
  	}

	/**
	 * Fonction principale
	 */
	execute(){
		monday.api(
			'{ me { id account { id name } }}'
		).then(res => {
			console.log(res);
			if(typeof res !== 'undefined'){
				if(typeof this.const['filterUser'] === 'undefined'){
					this.const['filterUser'] = res.data.me.id;
				}
				this.getUsers();
			}
		});
	}

	getUsers(){
		axios(this.state.api+'?name=users&cache='+Date.now()).then(response => response.data).then((data) => {
			if(data.users){
				let json = JSON.parse(data.users);
				console.log('Users :: Loaded from cache');
				this.const['users'] = json.users;
				this.getData();
			}else{
			};
		});
	}

	getData() {
		let filter = this.const['filterUser'];
		
		let dateMin;
		let dateMax;

		if(typeof this.const['dateUserMin'] === 'undefined' || this.const['dateUserMin'] === false){
			dateMin = false;
		}else{
			dateMin = moment(this.const['dateUserMin']).startOf('day').format('YYYY-MM-DD');
		}
		if(typeof this.const['dateUserMax'] === 'undefined' || this.const['dateUserMax'] === false){
			dateMax = false;
		}else{
			dateMax = moment(this.const['dateUserMax']).startOf('day').format('YYYY-MM-DD');
		}
		let params = '?users=1&name='+filter+(dateMin ? '&dateMin='+dateMin : '')+(dateMax ? '&dateMax='+dateMax : '')+"&cache="+Date.now();
		//console.log("paramss", params);
		axios(this.state.api+params).then(response => response.data).then((data) => {
			let json = data['users'];
			this.const['task_names'] = json.projet;
			this.const['sum_users_times'] = json.team;
			this.setState({loader: false});
			this.parseState();	
		});
	}

	/**
	 * Fonction ReactJS qui gère l'affichage
	 * @returns 
	 */
	render() {
		const tasks = this.state.task_names;
		const sums = this.state.sum_users_times; 
		if(typeof tasks === 'undefined' || typeof sums === 'undefined'){
			return <div className="App">
				<div className="empty">
					<div className="inner">
						<img src={logo} alt="Agence e+p" />
						<div>Chargement</div>
					</div>
 				</div>
			</div>;
		}else{

			let users = [];
			const filterUser = this.state.filterUser;
			const list_users = this.state.users;
			// console.log(this.state.users);
			// L'array de valeurs pour le select est généré à cet endroit car il faut que les valeurs soient renseignées dans le state
			Object.keys(list_users).map(function(user){				
				users[list_users[user].id] = {'label':list_users[user].name,'value':list_users[user].id,'team':list_users[user].team.name};
				if(typeof list_users[user].team.id != 'undefined'){
					if(typeof users[list_users[user].team.id] == 'undefined'){
						users[list_users[user].team.id] = [];
						users[list_users[user].team.id]['label'] = list_users[user].team.id === 505163 ? "Tous les "+list_users[user].team.name : " Toute l'équipe "+list_users[user].team.name;
						users[list_users[user].team.id]['team'] = list_users[user].team.name;
						users[list_users[user].team.id]['value'] = "";
					}
					if(users[list_users[user].team.id]['value'] !== ""){
						users[list_users[user].team.id]['value'] += ",";
					}
					//correctif pour le cas où il y a un seul user, pour éviter avoir même id que le team
					if(users[list_users[user].team.id]['value'] === ""){
						users[list_users[user].team.id]['value'] = "0,";
					}
					users[list_users[user].team.id]['value'] += list_users[user].id;
				}
			});

			users = users.filter(function(x){
				return x !== undefined;
			});
			//console.log(users);
			users.sort((a ,b) => ((a.label > b.label) && 1) || -1);

			// Ajout option tous les utilisateurs
			users.unshift({'label':'Tous les utilisateurs', 'team': " Toute l'équipe ",'value':0});
			
			return <div className="App">
				<Loader state={this.state.loader} />
				<div className="content">
					<div className="top-bar">
						<Tab active className="vr">Vue utilisateur</Tab>
						<Tab onClick={() => this.props.history.push("/clients")}>Vue client</Tab>
					</div>
					<div className="title-filtres">
						<Heading type={Heading.types.h2} value="Filtres"></Heading>
					</div>
					<div className="nav-bar">
						<div className="filters">
							<div className="filter-option">
								<label>Team / utilisateurs :</label>
								<SelectPicker 
									onChange={this.handleChange} 
									defaultValue={filterUser} 
									data={users} 
									groupBy="team" 
									style={{ width: 224 }} 
									sort={isGroup => {
										if (isGroup) {
										return (a, b) => {
											if (a < b) {
												return -1;
											}
											if (a > b) {
												return 1;
											}
											return 0;
										};
										}
								
										return (a, b) => {
											if (a < b) {
												return -1;
											}
											if (a > b) {
												return 1;
											}
											return 0;
											};
										}}/>
							</div>
							<div className="filter-option">
								<label>Période :</label>
								<DateRangePicker defaultValue={[startOfMonth(new Date()), endOfMonth(new Date())]} onChange={(dateRange) => { this.handleDateMin(dateRange[0]); this.handleDateMax(dateRange[1]); this.handleSubmit(); }} appearance="default" placeholder="Dates" style={{ width: 230 }} character=" - " ranges={periodes} format='DD/MM/YYYY' />
 							</div>
						</div>
					</div>

					{ sums.length === 0 && 
					<section>
						<p>Sur la période sélectionnée, aucun temps n'a été saisi.</p>
					</section>
					}

					{ sums.length > 0 && 
					<section>
						{Object.keys(sums).map(function(team_id){
							return <>
								<EquipeTable team={sums[team_id]} showHeures={true} />
							</>
						})}
					</section>
					}
					{ tasks.length > 0 && (filterUser !== 0) && 
					<section>
						<Heading type={Heading.types.h2} value="Par projets/tâches" />
						<BoardTable tasks={tasks} showHeures={true} />
					</section> 
					}
				</div>
			</div>;
		}
	}

	/**
	 * Fonction qui change le State sur la sélection d'un filtre
	 * @param {event} event 
	 */
	handleChange(event) {
		this.const['filterUser'] = event;
		this.setState({loader: true});
		this.getData();
	}
	
	/**
	 * Fonction qui change le State sur le bouton Appliquer
	 */
	handleSubmit() {
		this.setState({loader: true});
		this.getData();
	}

	/**
	 * Fonction qui change le State sur la sélection d'une date minimum
	 * @param {date} date 
	 */
	handleDateMin(date) {
		if(date == null){
			this.const['dateUserMin'] = false;
		}else{
			this.const['dateUserMin'] = date;
		}
		this.const['filterPeriode'] = "custom";
		this.setState({filterPeriode: this.const['filterPeriode'], dateUserMin: this.const['dateUserMin']});
	}

	/**
	 * Fonction qui change le State sur la sélection d'une date maximum
	 * @param {date} date 
	 */
	handleDateMax(date) {
		if(date == null){
			this.const['dateUserMax'] = false;
		}else{
			this.const['dateUserMax'] = date;
		}
		this.const['filterPeriode'] = "custom";
		this.setState({filterPeriode: this.const['filterPeriode'], dateUserMax: this.const['dateUserMax']});
	}


	/**
	 * Fonction qui régénère le State
	 * @param {date} date 
	 */
	parseState(){
		this.setState(this.const);
	}
}

export default App;