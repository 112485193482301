import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import App from "./App";
import Boardstat from "./Boardstat";
import Userstat from "./Userstat";

import * as serviceWorker from "./serviceWorker";
// import { BrowserRouter, Router, Route, IndexRoute, Link, Switch, useRouterHistory } from 'react-router-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import { createHashHistory } from 'history';

// const history = createHashHistory();

ReactDOM.render(
    <BrowserRouter>
	    <div className="App">
		<Switch>
			{/* <Route exact path="/testhtml" component={ Testhtml } /> */}
			<Route exact path="/" component={ Userstat } />
			<Route exact path="/clients" component={ Boardstat } />
		</Switch>
		</div>
    </BrowserRouter>,
    document.getElementById('root')
);
//ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
