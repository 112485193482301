import React, {Fragment} from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font
} from "@react-pdf/renderer";


import logo from './../assets/img/logo-ep.png'; // Tell webpack this JS file uses this image
import footerImg1 from './../assets/img/footer-pdf-1.jpg';
import footerImg2 from './../assets/img/footer-pdf-2.png';
import footerImg3 from './../assets/img/footer-pdf-3.jpg';
import Poppins from './../assets/fonts/Poppins-Regular.ttf'
import PoppinsBold from './../assets/fonts/Poppins-Bold.ttf'
import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();

var totalCharge, decompte;

Font.register({ family: 'Poppins', 
  format: "truetype",
  fontStyle: "normal",
  fontWeight: "normal",
  fonts: [
    { src: Poppins},
    { src: PoppinsBold, fontWeight: "bold" },
  ]});


const styles = StyleSheet.create({
	page: {
		flexDirection: 'row',
		backgroundColor: '#fff',
    fontFamily: 'Poppins',
    fontSize: 11,
    color: "red"
	},
	pageWrapper: {
    fontFamily: 'Poppins',
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 100,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
	},
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  pvaContainer: {
    backgroundColor: "#f6f6f5",
    display: "flex",
    flexDirection: "row",
    padding: 5
  },
  header: {
    position: 'absolute',
    top: 20,
    left: "50%",
    width: 100,
    height: 100,
    marginLeft: -20
  },
  pvaHeader: {
    marginTop: 0,
    marginBottom: 20,
    textAlign: "center",
  },
  pvaTitle: {
    fontSize: 16,
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    textTransform: "uppercase",
    marginTop: 20,
  },
  pvaOverview: {
    fontSize: 10
  },
  pvaDetails: {
    fontFamily: 'Poppins',
    fontSize: 11,
    marginTop: 0,
  },
  commentClient: {
    fontFamily: 'Poppins',
    fontSize: 11,
    marginTop: 20
  },
  pvaComment: {
    fontFamily: 'Poppins',
    fontSize: 11,
    width: '30%',
  },
  pvaRecap: {
    fontFamily: 'Poppins',
    fontSize: 11,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
  },
  pvaRecapCharge: {
    fontFamily: 'Poppins',
    fontSize: 11,
    width: '60%',
  },
  pucedItem: {
    marginLeft: 20
  },
  puce: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: 14,
  },
  pvaSignature: {
    textAlign: "right",
    marginTop: 50
  },
  image: {
    width: 103,
    height: 105,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  footerimage: {
    alignItems: "center",
    width: "95%"
  },
  subtitle: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: 150,
    alignItems: "center",
    marginBottom: 12
  },
  overviewContainer: {
    minHeight: 110
  },
  footer: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: 20,
    left: 45,
    right: 45,
    textAlign: "center",
    paddingTop: 50
  },
  boldStyle: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderBottomWidth: 0
},
col_container: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    backgroundColor: '#E7E3E3',
    alignItems: 'center',
    flexGrow: 1,
    fontSize: 10,
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    textTransform: "uppercase",
},
col_date: {
    width: '15%',
    borderRightWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 5
},
col_collab: {
    width: '30%',
    borderRightWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 5
},
col_intervention: {
    width: '40%',
    borderRightWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 5
},
col_charge: {
    width: '15%',
    paddingHorizontal: 10,
    paddingVertical: 5,
    textAlign: "center",
},
col_total: {
    width: '85%',
    borderRightWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 5,
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    textTransform: "uppercase",
},
col_total_charge: {
    width: '15%',
    paddingHorizontal: 10,
    paddingVertical: 5,
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    textAlign: "center"
},
row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    fontSize: 10
},
});

const PageWrapper = (props) => {
	return (
		<Page wrap style={styles.pageWrapper}>
				{props.children}
		</Page>
	)
}

const TaskItemsTable = ({tache, debut, fin}) => (
  <View style={styles.tableContainer}>
      <TaskTableHeader />
      <TaskTableRow items={tache} debut={debut} fin={fin} />
      <TaskTableTotal />
  </View>
);

const TaskTableHeader = () => (
  <View style={styles.col_container}>
      <Text style={styles.col_date}>Date</Text>
      <Text style={styles.col_collab}>Collaborateur</Text>
      <Text style={styles.col_intervention}>Intervention</Text>
      <Text style={styles.col_charge}>Charge</Text>
  </View>
);

const CommentAgence = ({items}) => {
  if (typeof items.pdf_comment !== 'undefined' && items.pdf_comment !== '' && items.pdf_comment !== null) {
    return (
      <View style={styles.pvaComment}>
        <Text style={styles.boldStyle}>Commentaires agence </Text>
        <Text>{items.pdf_comment}</Text>
      </View>
    )
  }
  return null;
}

const TaskItemsFooter = ({tache}) => (
  <View style={styles.pvaRecap}>
    <View style={styles.pvaRecapCharge}>
      <Text style={styles.boldStyle}>Récapitulatif </Text>
      <Text style={styles.pucedItem}><Text style={styles.puce}>• </Text>  Charge totale facturée : {roundNum(totalCharge)} jour(s)</Text>
      <Text style={styles.pucedItem}><Text style={styles.puce}>• </Text>  Nombre de jours restants : {roundNum((decompte - totalCharge )) } jour(s)</Text>
    </View>

    <CommentAgence items={tache} />

  </View>
)


const TaskTableRow = ({items, debut, fin}) => {
  const rows = Object.keys(items.task_names).map(function(task_names){
    let task = items.task_names[task_names];
    let usernames = "";
    const userlist = [];

    let taskStartDate = "";
    if (typeof task.status !== 'undefined' && task.status === 'LIVRE'
    && (typeof task.users_time !== 'undefined' && task.users_time !== null && task.users_time.length > 0)
    && (typeof task.last_date !== 'undefined' && 
    (debut <= task.last_date && fin >= task.last_date))) {
      totalCharge = totalCharge + parseFloat(roundNum(task.temps_h/7));
      task.users_time.map(function(time){
        // on met le nom de l'utilisateur une seule fois même s'il a saisit plusieurs fois sur la tâche
        if (userlist.indexOf(time.user_name) === -1) {
          userlist.push(time.user_name);
          if (usernames !== "") {
            usernames = usernames + "\n"
          }
          usernames = usernames + time.user_name ;
        }
        if (typeof task.users_time[0].start !== 'undefined' && task.users_time[0].start !== null) {
          taskStartDate = formatDateCustom(new Date(task.users_time[0].start));
        }
    })
    return (
      <View style={styles.row} key={task.id}>
          <Text style={styles.col_date}>{taskStartDate}</Text>
          <Text style={styles.col_collab}>{usernames}</Text>
          <Text style={styles.col_intervention}>{task.name}</Text>
          <Text style={styles.col_charge}>{roundNum(task.temps_h/7)} j</Text>
      </View>
    )}
  return null;
  });
  return (<Fragment>{rows}</Fragment> )
}

const TaskTableTotal = () => (
  <View style={styles.row}>
      <Text style={styles.col_total}>Total</Text>
      <Text style={styles.col_total_charge}>{roundNum(totalCharge)} j</Text>
  </View>
);


const DecomptePrec = ({tache}) => {
  if(typeof tache.pdf_decompte_prec !== 'undefined'  && tache.pdf_decompte_prec !== null && tache.pdf_decompte_prec !== ""){
    decompte = parseFloat(tache.pdf_decompte_prec);
  } else {
    // on va parcourir les taches de la pva
    let totalChargePva = 0;
    Object.keys(tache.task_names).forEach(task_names => {
      let task = tache.task_names[task_names];
      if (typeof task.users_time !== 'undefined' && task.users_time !== null && task.users_time.length > 0) {
        totalChargePva = totalChargePva + parseFloat(roundNum(task.temps_h/7));
      }
    })

    let nb_jours_vendus = parseFloat(tache.decompte) / 60 / 7 // correspond au nb jours vendus
  // correspond au (nb jours vendus - total temps h du board + charge du mois actuel pour retrouver le decompte précédent)
    decompte = parseFloat(nb_jours_vendus - parseFloat(tache.temps_h / 7) + totalChargePva);
    if (isNaN(decompte)) {
      decompte = 0;
    }
  }
  return (
    <Text><Text style={styles.boldStyle}>Solde restant de la période précédente :</Text> {roundNum(decompte)} jour(s)</Text>
  );
}

export function PdfDocument(props) {
  
var dateDuJour = formatDateCustom(new Date());
//console.log("pdf props", props);
var dateLim = "";
var dateDebut = "";
var dateFin = "";
var footerImg = footerImg1;
totalCharge = 0;
decompte = 0;
if(typeof props.data.pdf_datelim !== 'undefined'  && props.data.pdf_datelim !== null && props.data.pdf_datelim !== ""){
  dateLim = formatDateCustom(props.data.pdf_datelim);
}
if(typeof props.debut !== 'undefined'  && props.debut !== null && props.debut !== ""){
  //changer le format yyyy-mm-dd en dd/mm/yyyy pour l'affichage
  dateDebut = props.debut.substring(8, 10) + '/' + props.debut.substring(5, 7) + '/' + props.debut.substring(0, 4);
}
if(typeof props.fin !== 'undefined'  && props.fin !== null && props.fin !== ""){
  dateFin = props.fin.substring(8, 10) + '/' + props.fin.substring(5, 7) + '/' + props.fin.substring(0, 4);
}
if (typeof props.data.pdf_signature !== 'undefined' && props.data.pdf_signature !== '') {
  if (props.data.pdf_signature === 2) {
    footerImg = footerImg2;
  } else if (props.data.pdf_signature === 3) {
    footerImg = footerImg3;
  }
}

  return (
    <Document>
      <PageWrapper>
      <Image
          style={styles.image}
          source={logo}
        />
      <View style={styles.pvaHeader}>
        <Text style={styles.pvaTitle}>Procès verbal d'activité</Text>
      </View>
      <View style={styles.pvaDetails}>
        <Text><Text style={styles.boldStyle}>Date :</Text> {dateDuJour}</Text>
        <Text><Text style={styles.boldStyle}>Client :</Text> {props.data.client_name}</Text>
        <Text><Text style={styles.boldStyle}>Période :</Text> Du {dateDebut} au {dateFin}</Text>
        <Text><Text style={styles.boldStyle}>Prestation :</Text> {props.data.pdf_prestation}</Text>
        <Text><Text style={styles.boldStyle}>Devis / BDC n°</Text> {props.data.pdf_devis} </Text>
        <Text><Text style={styles.boldStyle}>Modalités de facturation :</Text> {props.data.pdf_modalites} </Text>
        <Text><Text style={styles.boldStyle}>Date limite de retour du présent PV :</Text> {dateLim} </Text>
        <DecomptePrec tache={props.data} />
      </View>
      <TaskItemsTable tache={props.data} debut={props.debut} fin={props.fin}/>

      <TaskItemsFooter tache={props.data} />

      <View style={styles.commentClient}>
        <Text style={styles.boldStyle}>Commentaires client </Text>
      </View>


      <View style={styles.pvaSignature}>
        <Text>Date et signature du client </Text>
      </View>

      <View fixed style={styles.footer}>
      <Image
            source={footerImg}
            style={styles.footerimage}
          />

      </View>
    </PageWrapper>
    </Document>
  );
}

	/**
	 * Date sous format dd/mm/yyyy
	 * @param {Date} pDate 
	 */
function formatDateCustom(pDate) {
  if (pDate !== '') {
    var str = pDate.toLocaleString('fr-FR').substring(0, 10);
    return str;
  }
  return null;
}

	/**
	 * Deux chiffres après la virgule
	 * @param {Number} pNum 
	 */
   function roundNum(pNum) {
    // Arrondit à 0.05
    //return (Math.floor(pNum / 0.05 ) * 0.05 + 0.05).toFixed(2);
    return pNum.toFixed(2);
   }